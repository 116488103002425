import Header from '../components/header';
import {useState} from "react";
import Search from '../components/search';
import {Person} from '../@types/common';
import DataView from "../components/mycheckup/DataView";

export default function Mycheckup(){
    const [type, setType] = useState('01');
    const [data, setData] = useState<Person[]>([]);
    const [modal, setModal] = useState(false);
    return (
        <div className='bg-[#F5F5F5] w-full h-screen'>
            <Header  type={type} setType={setType}/>
            <Search type={type} count={data.length} data={data} setData={setData} modal={modal} />
            <DataView  data={data} />
        </div>
    )
}
