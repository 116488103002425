import React, { SetStateAction} from "react";
import Logo from '../assets/images/top_logo.png';
import HeadButton from "./headButton";
import cn from "classnames";
import { Link } from 'react-router-dom';
import Logout from '../assets/images/icon-logout.png';
interface Props {
    type?: string,
    comp?: string,
    setType : React.Dispatch<SetStateAction<string>>,
}
export default function Header({comp,type, setType} : Props) {

    const logoutHandler = (e : React.MouseEvent<HTMLButtonElement>) =>{
        e.preventDefault();

        localStorage.clear();
        window.location.reload();
    }
    return (
        <div className='header'>
            <div className='head_div pt-2 flex_center'>
                <Link to={'/'}>
                    <img src={Logo} alt={'Logo'} width={200}/>
                </Link>
                <button className='ml-auto' onClick={logoutHandler}>
                    <img className='h-[35px] mr-4 rotate-180' src={Logout} alt={'로그아웃'} width={30}/>
                </button>
            </div>
            {
                comp === 'home' &&
                <>
                <div className='head_div flex items-center'>
                        <div className='head_button_group'>
                            <HeadButton type={'01'} text={'마이체크업'} setType={setType}
                                        className={cn('header_button', {'head_check': type === '01'})}/>
                            <HeadButton type={'02'} text={'벨류맵'} setType={setType}
                                        className={cn('header_button', {'head_check': type === '02'})}/>
                            <HeadButton type={'03'} text={'피트인'} setType={setType}
                                        className={cn('header_button', {'head_check': type === '03'})}/>
                            <HeadButton type={'04'} text={'더존'} setType={setType}
                                        className={cn('header_button', {'head_check': type === '04'})}/>
                        </div>
                    </div>
                    <div className='head_div pt-2 flex items-center text-white ml-4'>
                        {
                            type === '01' && <p>마이체크업</p>
                        }
                        {
                            type === '02' && <p>벨류맵</p>
                        }
                        {
                            type === '03' && <p>피트인</p>
                        }
                        {
                            type === '04' && <p>더존</p>
                        }
                    </div>
                </>
            }

        </div>
    )
}
