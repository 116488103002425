import {Person} from '../@types/common';
import * as XLSX from "xlsx-js-style";
import dayjs from 'dayjs';

export function getCompany(){
    const company = [
        {
            code : '01',
            name : '마이체크업',
            url : 'mycheckup'
        },
        {
            code : '02',
            name : '벨류맵',
            url : 'valuemap'
        },
        {
            code : '03',
            name : '피트인',
            url : 'pitin'
        }
    ];

    return company;
}

export function getUrl(code : number){
    const company = [
        {
            bpk : 1,
            name : '마이체크업',
            url : 'mycheckup'
        },
        {
            bpk : 2,
            name : '벨류맵',
            url : 'valuemap'
        },
        {
            bpk : 6,
            name : '피트인',
            url : 'pitin'
        }
    ];

    let result = null;
    company.forEach(function (element) {
        if(element.bpk === code) {
            console.log(element)
            result =  element;
        }
    })

    return result;
}
export function getCode(type : string){
    const company = [
        {
            type : '01',
            code : '1',
            name : '마이체크업'
        },
        {
            type : '02',
            code : '2',
            name : '벨류맵'
        },
        {
            type : '03',
            code : '6',
            name : '피트인'
        }
    ];

    for(let idx of company){
        if(idx.type === type) return idx.code;
    }
    return '00';
}


export function getName(type : string){
    const company = [
        {
            type : '01',
            code : '1',
            name : '마이체크업'
        },
        {
            type : '02',
            code : '2',
            name : '벨류맵'
        },
        {
            type : '03',
            code : '3',
            name : '피트인'
        }
    ];

    for(let idx of company){
        if(idx.type === type) return idx.name;
    }
    return '00';
}
export function excelDownload(type: string, data: Person[]): void {
    let today = dayjs().format('YYYY-MM-DD');
    let excel: Excel[] | undefined = excelData(type, data); // 타입 지정
    if (data.length <= 0) {
        console.error('데이터가 없습니다.');
        alert("데이터가 없습니다.")
        return;
    }
    if (!excel) {
        console.error('유효한 타입이 아닙니다.');
        return;
    }

    const excelHandler = {
        getExcelFileName: () => {
            return "유저 데이터.xlsx";
        },
        getSheetName: () => {
            return today + '_' + type;
        },
        getExcelData: () => {
            return excel;
        },
        getWorksheet: () => {
            const ws = XLSX.utils.json_to_sheet(excelHandler.getExcelData() || []); // undefined를 빈 배열로 처리

            // @ts-ignore
            const range = XLSX.utils.decode_range(ws['!ref']);

            // 각 행에 스타일을 적용합니다.
            for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                const row = XLSX.utils.encode_row(rowNum);
                for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
                    const cell = XLSX.utils.encode_col(colNum);
                    const cellRef = `${cell}${row}`;
                    // 특정 셀에 스타일을 설정합니다.
                    if (rowNum === 0) {
                        ws[cellRef].s = {
                            alignment: { horizontal: 'center' },
                            font: { bold: true, color: { rgb: 'FFFFFF' } },
                            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: '006ED0' } },
                        };
                    } else {
                        // 특정 열에는 배경색을 지정하지 않습니다.
                        ws[cellRef].s = {
                            alignment: { horizontal: 'center' },
                            // 필요한 다른 스타일 속성도 추가할 수 있습니다.
                        };
                        }
                }
            }
            ws['!cols'] = [
                {
                    wpx: 120,
                },
                {
                    wpx: 80,
                },
                {
                    wpx: 120,
                },
                {
                    wpx: 120,
                },
                {
                    wpx: 130,
                },
                {
                    wpx: 140,
                },
                {
                    wpx: 160,
                },
                {
                    wpx: 120,
                },
                {
                    wpx: 120,
                },
                {
                    wpx: 120,
                },
                {
                    wpx: 160,
                },
            ];
            return ws;
        },
    };

    const datas = excelHandler.getWorksheet();
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, datas, excelHandler.getSheetName());
    XLSX.writeFile(workbook, excelHandler.getExcelFileName());
}

interface Excel {
    고객키 : string,
    이름: string,
    생년월일: string,
    연락처: string,
    나이: string,
    접수일 : string,
    마켓팅수신동의 : string
}
interface Excel2 {
    고객키 : string,
    이름: string,
    생년월일: string,
    연락처: string,
    나이: string,
    이메일 : string;
    상태코드 : string | null,
    '상담예약(1차)' : string | null;
    '상담예약(2차)' : string | null;
    접수일 : string
    마켓팅수신동의 : string
}

function excelData(type: string, data: any[]): Excel[] | Excel2[] | undefined {
    console.log(data);
    if (type === '벨류맵') {
        return data.map((item) => ({
            고객키: item.cmpk,
            이름: item.cName,
            생년월일: item.cJumin,
            연락처: item.cCell,
            이메일 : item.cMail,
            나이: item.age,
            상태코드 : statusCode(item.saleStatus),
            '상담예약(1차)' : item.appointDT1st,
            '상담예약(2차)' : item.appointDT2nd,
            접수일 : dayjs(item.createdYMD).format('YYYY-MM-DD hh:mm:ss'),
            마켓팅수신동의 : item.marketingYN

    }));
    }
    if (type === '마이체크업') {
        return data.map((item) => ({
            고객키: item.cmpk,
            이름: item.cName,
            생년월일: item.cJumin,
            연락처: item.cCell,
            나이: item.age,
            접수일 : dayjs(item.createdYMD).format('YYYY-MM-DD hh:mm:ss'),
            마켓팅수신동의 : item.marketingYN
        }));
    }
    return undefined;
}



export function modalStyles(type : string){
    const pitIn = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex : 20,
        },
        content: {
            backgroundColor : "white",
            left: '0',
            margin: "auto",
            width: "700px",
            height : '700px',
            padding: "0",
            //transform : "translate(-50%,0)"
        },
    };

    const edit = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex : 20,
        },
        content: {
            backgroundColor : "white",
            left: '0',
            margin: "auto",
            width: "700px",
            height : '700px',
            padding: "0",
            //transform : "translate(-50%,0)"
        },
    };

    const add = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex : 20,
        },
        content: {
            backgroundColor : "white",
            left: '0',
            margin: "auto",
            width: "700px",
            height : '400px',
            padding: "0",
            //transform : "translate(-50%,0)"
        },
    };

    const memo = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex : 30,
        },
        content: {
            backgroundColor : "white",
            left: '0',
            margin: "auto",
            width: "700px",
            height : '650px',
            padding: "0",
            //transform : "translate(-50%,0)"
        },
    }

    const cnsl = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex : 20,
        },
        content: {
            backgroundColor : "#eeeeee",
            left: '0',
            margin: "auto",
            width: "460px",
            height : '750px',
            padding: "0",
            //transform : "translate(-50%,0)"
        },
    };
    let modalStyle = {
    };

    if(type === 'pitIn') {
        modalStyle = pitIn;
    }
    if(type === 'cnsl'){
        modalStyle = cnsl;
    }

    if(type === 'memo'){
        modalStyle = memo;
    }
    if(type === 'edit'){
        modalStyle = edit;
    }
    if(type === 'add'){
        modalStyle = add;
    }

    return modalStyle;
}

export function errorMsg(code : string){
    let msg = {
        age
            :
            "나이",
        birth
            :
            "생년월일",
        businessType
            :
            "사업자",
        cCell
            :
            "전화번호",
        cJumin
            :
            "주민등록번호",
        cMail
            :
            "이메일",
        cName
            :
            "이름",
        carNum
            :
            "차량번호",
        carType
            :
            "차종",
        carUse
            :
            "차량용도"
    }
    // @ts-ignore
    return msg[code];
}

export function statusCode(code : string ){
    let status = {
        '10' :
            "상담접수",
        '11'
            :
            "상담예약",
        '12'
            :
            "가입신청",
        '13'
            :
            "보류",
        '14'
            :
            "OBD설치 신청",
        '15'
            :
            "OBD설치 완료",
        '16'
            :
            "결제 요청",
        '17'
            :
            "결제 완료",
        '18'
            :
            "미결제",
        '19'
            :
            "서비스 개시",
    }
    // @ts-ignore
    return status[code];
}
export function getMsg(name : string | undefined, code : string | undefined ){
    return [
        {
            'index' : 0,
            'title' : '가입안내',
            'content' :
                `안녕하세요, 피트인 입니다.\n`+
                `${name}님! 요청하신 배터리 서비스 가입신청 링크 전달 드립니다.\n` +
                '\n' +
                '- 가입신청 링크:\n' +
                `https://pitin-ev.simg.kr/register?key=${code}` +
                '\n' +
                '감사합니다.\n' +
                '\n' +
                '※ 피트인 상담문의\n' +
                '- 전화: 1670-0470\n' +
                '- 상담시간: 오전 9시~오후 6시\n' +
                '(점심시간: 오전 11시 30분~오후 1시/공휴일 및 주말 제외)\n' +
                '\n' +
                '※ 피트인 홈페이지:\n' +
                'https://pitin-ev.simg.kr'
        }
    ]
}

