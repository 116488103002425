import React from "react"
import {Navigate, useLocation} from "react-router-dom"

const Authmiddleware = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation();
    const isAuthenticated = localStorage.getItem("token") !== null;

    if (!isAuthenticated && location.pathname !== "/login") {
        return <Navigate to="/login" replace />;
    }

    if (isAuthenticated && location.pathname === "/login") {
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
}


export default Authmiddleware;
