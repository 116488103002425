/**
 * @Author: rlarlejrwl56 63471869+rlarlejrwl56@users.noreply.github.com
 * @Date: 2024-08-29 10:59:48
 * @LastEditors: rlarlejrwl56 63471869+rlarlejrwl56@users.noreply.github.com
 * @LastEditTime: 2024-08-29 11:06:26
 * @FilePath: src/components/pitin/DataView.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import React, {SetStateAction, useState} from "react";
import {Person} from "../../@types/common";
import {statusCode} from "../../utils/common";
import dayjs from "dayjs";
import ModalComponent from "../modals/modalComponent";

interface Props {
    data : Person[],
    modal : boolean,
    setModal : React.Dispatch<SetStateAction<boolean>>
    setData : React.Dispatch<SetStateAction<Person[]>>
}

export default function DataView({ data, modal, setModal, setData } : Props){
    const [rowData, setRowData] = useState<Person>();
    
    return (
        <div className='min-w-[1400px] flex_center px-4 pb-12'>
            {
                data.length > 0 ?
                    <table className="table-auto w-[calc(100%-10%)] border shadow">
                        <thead className='h-[45px] font-[500]'>
                        <tr className='border-b pb-2'>
                            <th>
                                No.
                            </th>
                            <th>
                                고객키
                            </th>
                            <th>
                                신청자
                            </th>
                            <th>
                                핸드폰
                            </th>
                            <th>
                                생년월일
                            </th>
                            <th>
                                나이
                            </th>
                            <th>
                                사업자
                            </th>
                            <th>
                                차량용도
                            </th>
                            <th>
                                차종
                            </th>
                            <th>
                                차량번호
                            </th>
                            <th>
                                상담예약일
                            </th>
                            <th>
                                상태코드
                            </th>
                            <th>
                                마켓팅 동의
                            </th>
                            <th>
                                접수일
                            </th>
                        </tr>
                        </thead>
                        <tbody className='base_font bg-white'>
                        {
                            data.map((person, index) =>
                                <tr key={index}
                                    className="cursor-pointer hover:bg-blue-100"
                                    onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
                                        setModal(true);
                                        setRowData(person)
                                    }}>
                                    <td className='text-center h-[40px] border-b'>
                                        {index + 1}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.cmpk}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.cName}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.cCell}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.birth === null ? '미입력' : person.birth}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.age === null ? '미입력' : person.age}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.businessType === '' ? '미입력' : person.businessType}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.carUse === '' ? '미입력' : person.carUse}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.carType === '' ? '미입력' : person.carType}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.carNum === '' ? '미입력' : person.carNum}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.appointDT1st}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.saleStatus ? statusCode(person.saleStatus) : '미입력'}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.marketingYN}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {dayjs(person.createdYMD).format('YYYY년 MM월 DD일')}
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                    :
                    <div className='w-full flex_center'>
                        <div
                            className='w-full h-[60px] bg-white border rounded-xl mb-8 shadow px-4 flex_center'>
                            <p>조회 데이터가 없습니다.</p>
                        </div>
                    </div>
            }
        {
            modal && <ModalComponent isOpen={modal} setIsOpen={setModal} type={'pitIn'} rowData={rowData} btnType={'edit'} setData={setData}/>
        }
    </div>
)}