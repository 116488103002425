import {getCode, getName} from '../utils/common';
import React, { SetStateAction, useState, useEffect } from 'react';
import TextInput from '../components/commons/baseText';
import {excelDownload} from '../utils/common';
import Excel from '../assets/images/uxicon-excel.png';
import {Person} from '../@types/common';
import SearchIcon from '../assets/images/search.svg';
import dayjs from 'dayjs';
import ModalComponent from './modals/modalComponent';
import {adminBulk} from "../api/axiosInstans";
interface Props {
    type : string,
    count : number,
    data : Person[],
    setData : React.Dispatch<SetStateAction<Person[]>>
    modal : boolean
}

interface Param {
    [key: string]: string;
}


export default function Search({type, count, data, setData, modal} : Props){
    const today = dayjs().format('YYYY-MM-DD');
    const [cmpk, setCmpk] = useState('');
    const [cName, setCname] = useState('');
    const [cCell, setCcell] = useState('');
    const [cJumin, setCjumin] = useState('');
    const [toDate, setToDate] = useState(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
    const [fromDate, setFromDate] = useState(today);
    const [dateGbn, setDateGbn] = useState('01');
    const [status, setStatus] = useState('');
    const [open, setOpen] = useState(false);
    const fetchData = async () => {
        try {
            let bpk = getCode(type);
            let param : Param = {
                bpk : bpk,
                cmpk : cmpk,
                cName : cName,
                cCell : cCell,
                cJumin : cJumin,
                dateGbn : dateGbn,
                status : status,
                toDate: toDate,
                fromDate: fromDate
            }

            const {data}: any = await adminBulk.post('/searchData',param);
            setData(data);
            if(data.length <= 0 ){
                alert("데이터가 없습니다.")
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const onClickHandler = (e: React.MouseEvent) => {
        e.preventDefault();

        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, [modal, open]);


    return (
        <div className='pl-[90px] w-full mt-8 mb-6'>
            <div className='min-w-[1440px] px-4 '>
                <div className='flex w-full px-8'>
                    <div className='flex-col flex flex_center'>
                        <p className='font-bold'>{getName(type)}</p>
                        <p>총 : {count}건</p>
                    </div>
                    <div className='ml-auto flex_center space-x-4'>
                        <TextInput
                            classnames={'input-m'}
                            text={'이름'}
                            value={cName}
                            onChange={(e) => setCname(e.target.value)}
                        />
                        <TextInput
                            classnames={'input-m'}
                            text={'핸드폰'}
                            value={cCell}
                            onChange={(e) => setCcell(e.target.value)}
                        />
                        <TextInput
                            classnames={'input-m'}
                            text={'생년월일'}
                            value={cJumin}
                            onChange={(e) => setCjumin(e.target.value)}
                        />
                        <div className='flex_center'>
                            <label className='w-[60px] flex_center'>
                                상태
                            </label>
                            <select className='text_input'
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setStatus(e.currentTarget.value)}>
                                <option value={''}>
                                    전체
                                </option>
                                <option value={'10'}>
                                    상담예약
                                </option>
                                <option value={'11'}>
                                    상담
                                </option>
                                <option value={'12'}>
                                    가입신청
                                </option>
                            </select>
                        </div>
                        <div className='flex_center'>
                        <select className='text_input'
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setDateGbn(e.currentTarget.value)}>
                                <option value={'01'}>
                                    접수일자
                                </option>
                                <option value={'02'}>
                                    상담 예약 일자
                                </option>
                        </select>
                        </div>
                        <div className='flex_center'>
                            <input className='px-2 text_input' type={'date'} defaultValue={toDate}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => setToDate(e.target.value)}/>
                            <h2 className='mx-2'>~</h2>
                            <input className='px-2 text_input' type={'date'} defaultValue={fromDate}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFromDate(e.target.value)}/>
                        </div>

                        <div className='border flex_center w-[40px] h-[35px] rounded-md hover:bg-blue-200'>
                            <button className='outline-none' onClick={onClickHandler}>
                                <img src={SearchIcon} alt={'search'} width={20}/>
                            </button>
                        </div>
                        {
                            type === '03' &&
                            <button className='outline-none button_on' onClick={(e: React.MouseEvent) => setOpen(true)}>
                                신규등록
                            </button>
                        }
                        <div className='flex_center'>
                            <button
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => excelDownload(getName(type), data)}>
                                <img src={Excel} alt={'excel'} width={35}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                open &&
                <ModalComponent isOpen={open} setIsOpen={setOpen} type={'pitIn'} setData={setData} rowData={null}
                                btnType={'add'}/>
            }
        </div>
    )
}
