import React from "react"
import PropTypes from "prop-types"
import NavBar from "../components/navBar";

const NonAuthLayout = (props: any) => {
    return (
        <div>
            <NavBar/>
            <React.Fragment>{props.children}</React.Fragment>
        </div>
    )}

NonAuthLayout.propTypes = {
    children: PropTypes.any,
    location: PropTypes.object,
}

export default NonAuthLayout
