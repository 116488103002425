import Modal from 'react-modal';
import React, { SetStateAction, useEffect, useState } from 'react';
import { modalStyles } from '../../utils/common';
import Close from '../../assets/images/btn_close_24px.svg';
import Logo from '../../assets/images/logo2.png';
import {convertToMemoType, memoService } from '../../api/utils';
import dayjs from 'dayjs';
import { MemoType, memoParam } from '../../@types/common';

interface Props {
    cmpk ?: string;
    setIsOpen : React.Dispatch<SetStateAction<boolean>>;
}


export default function MemoComponent({cmpk, setIsOpen} : Props) {
    const isOpen = true;
    const styles= modalStyles('memo');
    const [edit, setEdit] = useState('');
    const [memo, setMemo] = useState<MemoType[]>();
    const [showRepl, setShowRepl] = useState(false);
    const [memoEdit, setMemoEdit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [repl, setReple] = useState('');
    const saveEdit = async (type : string) =>{
        try{
            if(cmpk){
                let param: memoParam = {
                    bopk: memo && memo[0].bopk ? memo[0].bopk  : 0,
                    title: '',
                    contents: type !== 'inRepl' ? edit : repl,
                    group_depth: 0,
                    created_ymd: '',
                    cmpk: cmpk,
                    type: type
                }
                await memoService(param).then((data) => {
                    alert(data[0].msg);
                    setIsOpen(false);
                });

            }
        }catch(e){
            console.log(e);
        }
    }
    const onActiveEvent = (e : React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter'){
            saveEdit('inRepl');
        }
    }

    useEffect(() => {
        if (cmpk) {
            let param : memoParam = {
                bopk : 0,
                title : '',
                contents : '',
                group_depth : 0,
                created_ymd : '',
                cmpk : cmpk,
                type : 'get'
            }
            memoService(param).then((data) => {
                    const memoData = convertToMemoType(data);
                    setMemo(memoData);
                    memoData[0].bopk !== undefined && setShowRepl(true);
            }).catch(error => {
                console.error(error);
            });
        }
    }, []);

    return(
        <Modal isOpen={isOpen} style={styles}>
            <div className='flex_center h-[60px] border-b fixed w-[700px] bg_base'>
                <p className='flex-1 text-[16px] font-[600] text-white text-center'>메모</p>
                <button className='flex items-end mr-4' onClick={(e: React.MouseEvent) => setIsOpen(!isOpen)}>
                    <img src={Close} alt={'close'}/>
                </button>
            </div>
            {
                memo && memo.length > 0 && (
                <div className='flex_center flex-col  mt-[70px] mb-[30px] space-y-4 mx-8 border base_font p-8  overflow-y-auto'>
                    <div className='flex w-full py-4  items-center'>
                        <div className='border rounded-full w-[50px] h-[50px] flex_center bg-[#e7f0ef]'>
                            <img src={Logo} alt={'logo'}/>
                        </div>
                            <p className='ml-4'>
                                {memo[0].title} {memo[0].created_ymd}
                            </p>
                        <div className='ml-auto relative'>
                            <button onClick={(e : React.MouseEvent<HTMLButtonElement>) => setMemoEdit(!memoEdit)}>
                                ...
                            </button>
                            {
                                memoEdit &&
                                <div className='top-8 bg-white rounded-md shadow-md right-0 absolute flex_center flex-col border w-[100px] space-y-4 py-2'>
                                    <button className='hover:font-bold' onClick={(e : React.MouseEvent<HTMLButtonElement>) => {
                                        setIsEdit(!isEdit);
                                        setMemoEdit(!memoEdit);
                                    }}>수정</button>
                                    <button className='hover:font-bold' onClick={(e : React.MouseEvent) => saveEdit('delete')}>삭제</button>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        memo[0].contents === undefined ?
                            <textarea
                                className='border w-full bg-[#eceff1] hover:outline-none focus:outline-none p-4 h-[300px]'
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEdit(e.currentTarget.value)}/>
                            :
                            <textarea
                                className='border w-full bg-[#eceff1] hover:outline-none focus:outline-none p-4 h-[300px]'
                                defaultValue={memo[0].contents}
                                disabled={!isEdit}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEdit(e.currentTarget.value)}/>
                    }
                    {
                        memo && memo[0].contents &&
                        <div className='flex_center w-full flex-col'>
                            <div className='base_font w-full text-[14px] flex'>
                                <button className='text-[#16397a] hover:font-bold'
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setShowRepl(!showRepl)}>
                                        댓글보기
                                    </button>
                                    <p className='text-[11px] ml-auto'>
                                        댓글 {memo.length - 1}개
                                    </p>
                                </div>
                            {
                                showRepl &&
                                <div className='base_font w-full text-[14px] flex flex-col space-y-4 bg-[#eceff1] px-2 py-2'>
                                    {
                                        memo.filter(state => state.group_depth >= 1).map((state, idx) =>
                                            <div key={idx} className='flex w-full flex-col'>
                                                <div>
                                                    {state.created_ymd} : <input type={'text'} value= {state.contents} disabled={true} />
                                                </div>
                                                <div className='flex ml-auto space-x-2'>
                                                    <button>수정</button>
                                                    <button>삭제</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            }
                                <div className='w-full h-[40px] border rounded-md my-2'>
                                    <input className='w-full h-full px-2 hover:outline-none focus:outline-none' type='text' placeholder='댓글 쓰기' onChange={(e:React.ChangeEvent<HTMLInputElement>) => setReple(e.currentTarget.value)} onKeyDown={onActiveEvent}/>
                                </div>
                            </div>
                        }
                        {
                            isEdit &&
                                <div>
                                    <button  className='bg_base text-white w-[120px] h-[35px] flex_center rounded-md'
                                            onClick={(e) => saveEdit('update')}>저장
                                    </button>
                                </div>
                        }
                        {
                            memo && memo[0].contents === undefined &&
                                <div>
                                    <button  className='bg_base text-white w-[120px] h-[35px] flex_center rounded-md'
                                            onClick={(e) => saveEdit('insert')}>저장
                                    </button>
                                </div>
                        }
                </div>
            )}
        </Modal>
    )
}
