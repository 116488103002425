import Header from '../components/header';
import {useEffect, useState} from "react";
import Search from '../components/search';
import {Person} from '../@types/common';
import DataView from "../components/pitin/DataView";

export default function Pitin(){
    const [type, setType] = useState('03');
    const [data, setData] = useState<Person[]>([]);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (modal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [modal]);
    return (
        <div className='bg-[#F5F5F5] min-w-[1600px] w-full h-screen'>
            <Header  type={type} setType={setType}/>
            <Search type={type} count={data.length} data={data} setData={setData} modal={modal} />
            <DataView data={data} setData={setData}  modal={modal} setModal={setModal} />
        </div>
    )
}
