import React from "react";
import CustomSlider from "./customSlider";
import cn from 'classnames';
import {Person} from '../@types/common';
interface Props {
    card : boolean,
    data :Person[]
}
export default function DashBoard({card, data} : Props){
    return (
        <div className={cn('pl-[170px] w-[calc(100%-130px)]')}>
            <div className='py-6 w-full text-[16px]'>
                <p className='text-bold'>
                    오늘 가입 현황
                </p>
            </div>
            <CustomSlider data={data}/>
        </div>
    )
}
