import React from "react"
import { Navigate } from "react-router-dom"
import Home from '../pages/home';
import Mycheckup from "../pages/mycheckup";
import Valuemap from "../pages/valuemap";
import Pitin from "../pages/pitin";
import Login from "../components/Login";

interface RouteProps {
    path: string
    component: any
    exact?: boolean
}


// 비로그인
const authRoutes: Array<RouteProps> = [
    //Authentication pages
    { path: "/login", component: <Login /> }
]

// 로그인 한 유저 페이지 경로
const userRoutes: Array<RouteProps> = [
    { path: "/",exact: true, component: <Home /> },
    { path: "/mycheckup",exact: true, component: <Mycheckup /> },
    { path: "/valuemap",exact: true, component: <Valuemap /> },
    { path: "/pitin",exact: true, component: <Pitin /> },
]

export { userRoutes, authRoutes }
