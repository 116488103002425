import React from 'react';
import './assets/css/common.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Modal from "react-modal";
import {authRoutes, userRoutes} from "./routes/Routes";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import NonAuthLayout from "./pages/NonAuthLayout";
import {useAxiosInterceptors} from "./api/axiosInstans";


function App() {
    useAxiosInterceptors();
  return (
      <div className="App font-[Pretendard] base_font">
          <BrowserRouter>
              <Routes>
                  {authRoutes.map((route, idx) => (
                      <Route
                          path={route.path}
                          element={
                              <Authmiddleware>
                                  {route.component}
                              </Authmiddleware>
                          }
                          key={idx}
                      />
                  ))}
                  {userRoutes.map((route, idx) => (
                      <Route
                          path={route.path}
                          element={
                              <Authmiddleware>
                                  <NonAuthLayout>{route.component}</NonAuthLayout>
                              </Authmiddleware>
                          }
                          key={idx}
                      />
                  ))}
              </Routes>
          </BrowserRouter>
      </div>
  );
}

Modal.setAppElement('#root')
export default App;
