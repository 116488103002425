import Modal from 'react-modal';
import {SubmitHandler, useForm} from "react-hook-form";
import React, {SetStateAction, useEffect, useState} from "react";
import {errorMsg, getMsg, modalStyles} from '../../utils/common';
import {EreqParam, Param, Person, memoParam, reParam} from '../../@types/common';
import cn from 'classnames';
import Close from '../../assets/images/btn_close_24px.svg';
import {sendMsg, updateUser, newUser, deleteUser, fetchData, memoService, convertToMemoType} from '../../api/utils';
import MemoComponant from './memoComponant';
import dayjs from 'dayjs';

interface Props{
    isOpen : boolean;
    setIsOpen : React.Dispatch<SetStateAction<boolean>>;
    type : string;
    rowData ?: Person | null;
    btnType : string;
    setData : React.Dispatch<SetStateAction<Person[]>>;
}


export default function ModalComponent({isOpen, setIsOpen, type, rowData, btnType, setData} : Props){
    const styles = modalStyles(btnType);
    const [smsOpen, setSmsOpen] = useState(false);
    const [cell, setCell] = useState(rowData?.cCell);
    const [selectMsg, setSelectMsg] = useState<number>(0);
    const [isMemo, setIsMemo] = useState(false);
    const [memoCount, setMemoCount] = useState(0);
    const today = dayjs().format('YYYY-MM-DD');
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<EreqParam>({
        defaultValues: {
            cmpk : rowData?.cmpk,
            cName : rowData?.cName,
            cCell : rowData?.cCell,
            birth : rowData?.birth,
            cJumin : rowData?.cJumin,
            carNum : rowData?.carNum,
            inType : rowData?.inType,
            appointDT1st : rowData?.appointDT1st,
            businessType : rowData?.businessType,
            saleStatus : rowData?.saleStatus,
            carType : rowData?.carType,
            carUse : rowData?.carUse,
            job : ''
        },
        mode: 'onChange',
    });


    const onClickHandler = (e : React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const id = e.currentTarget.id;
        let msg = '';

        if(id === 'edit'){
            msg = '사용자 정보를 수정하시겠습니까?';
            if (window.confirm(msg)){
                setValue('job', 'up');
                handleSubmit(onSubmit)();
            }else{
                return;
            }
        }

        if(id === 'add'){
            msg = '가입 신청하시겠습니까?';
            if (window.confirm(msg)){
                setValue('job', 'new');
                setValue('inType', 'in');
                handleSubmit(onSubmit)();
            }else{
                return;
            }
        }

        if(id === 'cancel'){
            msg = '접수를 취소하시겠습니까?';
            if (window.confirm(msg)){
                setValue('job', 'cancel');
                handleSubmit(onSubmit)();
            }else{
                return;
            }
        }

        if(id === 'memo'){
            setIsMemo(true);
        }

        if(id === 'pdf'){
            if(rowData?.location){
                const pdfUrl = rowData.location;
                // PDF 파일 다운로드 링크 생성
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.setAttribute('download', `${rowData?.cName} 계약서.pdf`);
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else {
                alert('서비스 오류입니다.');
            }
        }
    }
    const onSubmit : SubmitHandler<EreqParam> = async (param : EreqParam) => {
        try {
            if(param.carUse === '택시') param.carUse = '01';
            if(param.carUse === '화물') param.carUse = '02';
            if(param.businessType === '개인') param.businessType = '01';
            if(param.businessType === '법인') param.businessType = '02';

            if(param.job === 'up'){
                await updateUser(param).then(data => {
                    alert(data.msg);
                });
            }


            if(param.job === 'cancel'){
                await deleteUser(param).then(data => {
                    alert(data.msg);
                });
            }

            if(param.job === 'new'){
                let result = await newUser(param);

                alert(result.msg);
            }

            setIsOpen(false);
        }catch(e){
            alert('서비스 오류입니다.');
        }
    }

    useEffect(() => {
        if(rowData){
            let keys = Object.keys(rowData);
            for(let key of keys){
                if(key !== 'age' && key !== 'cMail'){
                    // @ts-ignore
                    if(rowData[key] === 'undefined' || rowData[key] === '' || rowData[key] === null){
                        if(key === 'birth' || key === 'carNum' || key === 'cJumin'){
                            setValue(key, '미입력');
                        }else {
                            // @ts-ignore
                            setValue(key, '00');
                        }
                    }
                }
            }
                setValue('cmpk', rowData.cmpk);
        }
    }, [rowData]);

    useEffect(() => {
        if(rowData?.cmpk){
            console.log(rowData?.cmpk)
            let param : memoParam = {
                bopk : 0,
                title : '',
                contents : '',
                group_depth : 0,
                created_ymd : '',
                cmpk : rowData.cmpk,
                type : 'get'
            }
            memoService(param).then((data) => {
                const memoData = convertToMemoType(data);
                memoData && memoData[0].bopk !== undefined ? setMemoCount(memoData.length) : setMemoCount(0);
            }).catch(error => {
                console.error(error);
            });
        }
    }, [isMemo]);

    return(
        <Modal isOpen={isOpen} style={styles}>
            <div className='h-full w-full'>
                <div className='flex_center h-[60px] border-b w-full bg_base'>
                    <p className='flex-1 text-[16px] font-[600] text-white text-center'>고객 정보</p>
                    <button className='flex items-end mr-4' onClick={(e: React.MouseEvent) => setIsOpen(!isOpen)}>
                        <img src={Close} alt={'close'}/>
                    </button>
                </div>
                {
                    btnType === 'add' ?
                        <form id='userForm' className={cn('w-full flex', {'justify-center': !smsOpen})}
                              onSubmit={handleSubmit(onSubmit)}>
                            <table
                                className={cn('mt-8 bg-white  base_font text-[14px] border rounded-lg  shadow-md w-[calc(100%-10%)] h-[200px]')}>
                                <tbody>
                                <tr className='h-[30px] bg-[#eceff1]'>
                                    <td className='pl-2'>
                                        이름 :
                                    </td>
                                    <td>
                                        <input type={'text'} className='input_hover border border-gray-200 bg-white rounded-md'
                                               {...register('cName')}
                                        />
                                    </td>
                                </tr>
                                <tr className='h-[30px] '>
                                    <td className='pl-2'>
                                        전화번호 :
                                    </td>
                                    <td>
                                        <input type={'text'} className='input_hover border border-gray-200 bg-white rounded-md'
                                               {...register('cCell')}
                                        />
                                    </td>
                                </tr>
                                <tr className='h-[30px] bg-[#eceff1]'>
                                    <td className='pl-2'>
                                        상담일자 :
                                    </td>
                                    <td>
                                        <input type='date' className='w-[156px] rounded-md px-2' min={today} {...register('appointDT1st')}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            {
                                smsOpen &&
                                <div className='w-[300px] h-full base_font text-[14px]'>
                                    <div
                                        className='flex items-center flex-col border mt-8 mx-2 shadow-md rounded-lg py-2 px-2'>
                                        <div className='border rounded-lg w-full base_font'>
                                            <select className='w-full h-full text-center'
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectMsg(parseInt(e.currentTarget.value))}>
                                                {getMsg(rowData?.cName, rowData?.cmpk).map((state, index) =>
                                                    <option key={index} value={index}>{state.title}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className='whitespace-pre-wrap mt-4 border rounded-lg p-2'>
                                            <p>{getMsg(rowData?.cName, rowData?.url)[selectMsg].content}</p>
                                        </div>
                                        <div className='border rounded-lg w-full base_font h-[30px] mt-2'>
                                            <input type='text' className='w-full h-full text-center' placeholder='전화번호'
                                                   defaultValue={cell}
                                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCell(e.currentTarget.value)}/>
                                        </div>
                                        <div className='border rounded-lg w-full base_font h-[30px] mt-2'>
                                            <button type='button'
                                                    className='w-full h-full text-center hover:bg_base hover:text-white'
                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => sendMsg(cell, getMsg(rowData?.cName, rowData?.url)[selectMsg].content)}>문자보내기
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </form>
                        :
                        <form id='userForm' className={cn('w-full flex', {'justify-center': !smsOpen})}
                              onSubmit={handleSubmit(onSubmit)}>
                            <table
                                className={cn('mt-8 scroll-y-auto bg-white  base_font text-[14px] border rounded-lg  shadow-md', {
                                    'w-[calc(100%-40%)] mx-2': smsOpen,
                                    'w-[calc(100%-10%)] h-[500px]': !smsOpen
                                })}>
                                <tbody>
                                <tr className='h-[30px]'>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        접수번호
                                    </td>
                                    <td>
                                        {rowData?.cmpk}
                                    </td>
                                </tr>
                                <tr className='h-[30px] bg-[#eceff1]'>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        이름
                                    </td>
                                    <td>
                                        <input type={'text'} className='input_hover border border-gray-200 bg-transparent rounded-md h-[30px] px-2'
                                               {...register('cName')}
                                        />
                                    </td>
                                </tr>
                                <tr className='h-[30px]'>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        생년월일
                                    </td>
                                    <td className={cn({'text-red-500': rowData?.birth === null})}>
                                        <input type={'text'} className='border border-gray-200 bg-transparent rounded-md h-[30px] px-2 input_hover' maxLength={6}
                                               {...register('birth')}
                                        />
                                    </td>
                                </tr>
                                <tr className='h-[30px]'>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        주민등록번호
                                    </td>
                                    <td className={cn({'text-red-500': rowData?.cJumin === null})}>
                                        <input type={'text'} className='border border-gray-200 bg-transparent rounded-md h-[30px] px-2 input_hover' maxLength={13}
                                               {...register('cJumin')}
                                        />
                                    </td>
                                </tr>
                                <tr className='h-[30px] bg-[#eceff1]'>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        전화번호
                                    </td>
                                    <td>
                                        <input type={'text'} className='border border-gray-200 bg-transparent rounded-md h-[30px] px-2 input_hover'
                                               {...register('cCell')}
                                        />
                                    </td>
                                </tr>
                                <tr className='h-[30px]'>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        나이
                                    </td>
                                    <td className={cn({'text-red-500': rowData?.age === undefined})}>
                                        {rowData?.age !== undefined ? rowData?.age + '세' : '미입력'}
                                    </td>
                                </tr>
                                <tr className='h-[30px] bg-[#eceff1]'>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        사업자
                                    </td>
                                    <td className={cn({'text-red-500': rowData?.businessType === ''})}>
                                        <select
                                            className='border border-gray-200 bg-transparent rounded-md h-[30px] px-2 input_hover' {...register('businessType')}>
                                            <option value={'00'}>미입력</option>
                                            <option value={'개인'}>개인</option>
                                            <option value={'법인'}>법인</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr className='h-[30px] '>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        차량용도
                                    </td>
                                    <td className={cn({'text-red-500': rowData?.carUse === ''})}>
                                        <select className='border border-gray-200 bg-transparent rounded-md h-[30px] px-2 input_hover' {...register('carUse')}>
                                            <option value={'00'}>미입력</option>
                                            <option value={'택시'}>택시</option>
                                            <option value={'화물'}>화물</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr className='h-[30px] bg-[#eceff1]'>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        차종
                                    </td>
                                    <td className={cn({'text-red-500': rowData?.carType === 'undefined'})}>
                                        <select className='border border-gray-200 bg-transparent rounded-md h-[30px] px-2 input_hover' {...register('carType')}>
                                            <option value={'00'}>미입력</option>
                                            <option value='니로(DE) EV'>니로(DE) EV</option>
                                            <option value='EV6'>EV6</option>
                                            <option value='아이오닉 5 EV (톰레인지)'>아이오닉 5 EV (톰레인지)</option>
                                            <option value='봉고 EV'>봉고 EV</option>
                                            <option value='포터 EV'>포터 EV</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr className='h-[30px] '>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        차량번호
                                    </td>
                                    <td className={cn({'text-red-500': rowData?.carNum === 'undefined'})}>
                                        <input type={'text'}
                                               className='border border-gray-200 bg-transparent rounded-md h-[30px] px-2 input_hover' {...register('carNum')}/>
                                    </td>
                                </tr>
                                <tr className='h-[30px] bg-[#eceff1]'>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        접수상태
                                    </td>
                                    <td>
                                        <select className='border border-gray-200 bg-transparent rounded-md h-[30px] px-2 input_hover'
                                                defaultValue={(rowData?.saleStatus !== 'undefined' ? rowData?.saleStatus : '미입력') || ''} {...register('saleStatus')}>
                                            <option value={'10'}>
                                                상담예약
                                            </option>
                                            <option value={'11'}>
                                                상담
                                            </option>
                                            <option value={'12'}>
                                                가입신청
                                            </option>
                                            <option value={'13'}>
                                                보류
                                            </option>
                                            <option value={'14'}>
                                                OBD설치 신청
                                            </option>
                                            <option value={'15'}>
                                                OBD설치 완료
                                            </option>
                                            <option value={'16'}>
                                                결제요청
                                            </option>
                                            <option value={'17'}>
                                                결제 완료
                                            </option>
                                            <option value={'18'}>
                                                미결제
                                            </option>
                                            <option value={'19'}>
                                                서비스개시
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr className='h-[30px] '>
                                    <td className='pl-8 text-gray-800 font-medium'>
                                        상담일자
                                    </td>
                                    <td>
                                        {rowData?.appointDT1st}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            {
                                smsOpen &&
                                <div className='w-[300px] h-full base_font text-[14px]'>
                                    <div
                                        className='flex items-center flex-col border mt-8 mx-2 shadow-md rounded-lg py-2 px-2'>
                                        <div className='border rounded-lg w-full base_font'>
                                            <select className='w-full h-full text-center'
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectMsg(parseInt(e.currentTarget.value))}>
                                                {getMsg(rowData?.cName, rowData?.cmpk).map((state, index) =>
                                                    <option key={index} value={index}>{state.title}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className='whitespace-pre-wrap mt-4 border rounded-lg p-2'>
                                            <p>{getMsg(rowData?.cName, rowData?.url)[selectMsg].content}</p>
                                        </div>
                                        <div className='border rounded-lg w-full base_font h-[30px] mt-2'>
                                            <input type='text' className='w-full h-full text-center' placeholder='전화번호'
                                                   defaultValue={cell}
                                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCell(e.currentTarget.value)}/>
                                        </div>
                                        <div className='border rounded-lg w-full base_font h-[30px] mt-2'>
                                            <button type='button'
                                                    className='w-full h-full text-center hover:bg_base hover:text-white'
                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => sendMsg(cell, getMsg(rowData?.cName, rowData?.url)[selectMsg].content)}>문자보내기
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </form>
                }

                <div className='absolute bottom-0 w-full left-0 h-[80px] bg-[#eceff1] flex_center space-x-4'>
                    {
                        btnType === 'add' ?
                        <button type='button' id={'add'} onClick={onClickHandler}
                                className='bg_base text-white shadow-lg w-[120px] h-[35px] flex_center rounded-md'>신청</button>
                        :
                        <button type='button' id={'cancel'} onClick={onClickHandler}
                                className='bg-red-500 text-white shadow-lg w-[120px] h-[35px] flex_center rounded-md'>접수취소</button>
                    }
                    {
                        rowData?.saleStatus === '12' &&
                        <button type='button' id='pdf' onClick={onClickHandler}
                                className={cn('bg_base text-white shadow-lg w-[120px] h-[35px] flex_center rounded-md')}>
                            계약서
                        </button>
                    }
                    <button type='button' id={'edit'} onClick={onClickHandler}
                            className={cn('bg_base text-white shadow-lg w-[120px] h-[35px] flex_center rounded-md', {'bg-gray-500': btnType === 'add'})}
                            disabled={btnType === 'add'}>수정
                    </button>
                    <div className='relative'>
                        <button type='button' id={'memo'}
                                className={cn('relative bg-white shadow-lg w-[120px] h-[35px] flex_center rounded-md', {'bg-gray-500': btnType === 'add'})}
                                disabled={btnType === 'add'} onClick={onClickHandler}>메모
                        </button>
                        <div className='absolute -top-4 right-0 w-[30px] h-[30px] bg_base flex_center font-bold rounded-full text-white'>
                            {memoCount}
                        </div>
                    </div>
                    <button type='button'
                            className={cn('bg_base text-white shadow-lg w-[120px] h-[35px] flex_center rounded-md', {'bg-gray-500': btnType === 'add'})}
                            disabled={btnType === 'add'}
                            onClick={(e: React.MouseEvent) => setSmsOpen(!smsOpen)}>SMS
                    </button>
                </div>
            </div>
            {
                isMemo && <MemoComponant setIsOpen={setIsMemo} cmpk={rowData?.cmpk}/>
            }
        </Modal>
    )
}
