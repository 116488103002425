/**
 * @Author: rlarlejrwl56 63471869+rlarlejrwl56@users.noreply.github.com
 * @Date: 2024-08-29 13:24:45
 * @LastEditors: rlarlejrwl56 63471869+rlarlejrwl56@users.noreply.github.com
 * @LastEditTime: 2024-08-30 17:23:10
 * @FilePath: src/components/Login.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import TopLogo from '../assets/images/SIMG_가로형 로고_컬러.png'
import Logo from '../assets/images/logo2.png';
import Illustration from '../assets/images/illustration.png';
import React, {useEffect, useState} from "react";
import {adminBulk} from "../api/axiosInstans";
import {useNavigate} from "react-router-dom";
interface UserInfo {
    userId : string;
    password : string;
}
export default function Login() {
    const [userInfo, setUserInfo] = useState<UserInfo>({
        userId : '',
        password : ''
    });
    const navigate = useNavigate();
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target;
        
        const { value } = e.target;
        setUserInfo((prev: UserInfo) => ({...prev, [name]: value}));
    };
    const handlerKeyUp = (e : React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        
        if(key === 'Enter'){
            onClickHandler(e);
        }
    }
    const onClickHandler = async (e : React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        
        let {data} = await adminBulk.post('/login', userInfo);
        
        if(data.code !== '400'){
            navigate('/');
        }else {
            alert(data.msg);
        }
    }
   
    return(
            <div className=" h-screen flex_center">
                <div className='w-full h-full basis-1/2 bg_base '>
                </div>
                <div className='w-full h-full basis-1/2 bg-[#d9e2eb]'>
                </div>
                <div className='absolute w-[calc(100%-10%)] bg-white opacity-85 h-[80%] rounded-xl px-4 py-8 flex'>
                    <div className='basis-1/2 h-full'>
                        <div>
                            <img src={TopLogo} width={300} alt={'로고'}/>
                        </div>
                        <div className='flex_center h-[80%]'>
                            <img src={Illustration} alt={'그림'}/>
                        </div>
                    </div>
                    <div className='basis-1/2 flex_center px-8'>
                        <div className='bg-[#e1eefa] bg-opacity-60 text-black w-[calc(100%-40%)] h-[calc(100%-10%)] flex items-center flex-col py-16 px-4 rounded-lg'>
                            <div className='flex-col flex items-center'>
                                <img src={Logo} alt={'로고'} width={100}  className='h-[60px]'/>
                                <p className='tracking-wider text-[#0e47a1] text-[34px] font-semibold'>
                                    Login
                                </p>
                            </div>
                            <form className='flex flex-col space-y-16'>
                                <div className='flex flex-col space-y-4 mt-16'>
                                    <div className='flex_center space-x-2'>
                                        <input type='text' name={'userId'} className='px-2 w-[200px] h-[40px] rounded-lg' placeholder='아이디' onChange={handleInputChange}/>
                                    </div>
                                    <div className='flex_center space-x-2'>
                                        <input type='password' name={'password'} className='px-2 w-[200px] h-[40px] rounded-lg ' placeholder='비밀번호' onKeyUp={handlerKeyUp} onChange={handleInputChange}/>
                                    </div>
                                </div>
                                <div className='w-[200px] h-[40px] rounded-lg border bg_base text-white font-semibold flex_center'>
                                    <button type={"button"} className='w-full h-full' onClick={onClickHandler}>로그인</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}