/**
 * @Author: rlarlejrwl56 63471869+rlarlejrwl56@users.noreply.github.com
 * @Date: 2024-08-29 10:48:00
 * @LastEditors: rlarlejrwl56 63471869+rlarlejrwl56@users.noreply.github.com
 * @LastEditTime: 2024-08-29 11:07:17
 * @FilePath: src/components/mycheckup/DataView.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import dayjs from "dayjs";
import React from "react";


export default function DataView({data} : any){
    return (
        <div className=' min-w-[1400px] flex_center px-4'>
            {
                data.length > 0 ?
                    <table className="table-auto w-[calc(100%-10%)] border shadow ">
                        <thead className='h-[45px] font-[500]'>
                        <tr className='border-b pb-2'>
                            <th>
                                No.
                            </th>
                            <th>
                                고객키
                            </th>
                            <th>
                                신청자
                            </th>
                            <th>
                                핸드폰
                            </th>
                            <th>
                                생년월일
                            </th>
                            <th>
                                나이
                            </th>
                            <th>
                                마켓팅 동의
                            </th>
                            <th>
                                접수일
                            </th>
                        </tr>
                        </thead>
                        <tbody className='base_font bg-white'>
                        {
                            data.map((person : any, index : number) =>
                                <tr key={index}
                                    className="hover:bg-blue-100">
                                    <td className='text-center h-[40px] border-b'>
                                        {index + 1}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.cmpk}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.cName}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.cCell}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.birth}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.age}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {person.marketingYN}
                                    </td>
                                    <td className='text-center h-[40px] border-b'>
                                        {dayjs(person.createdYMD).format('YYYY년 MM월 DD일')}
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                    :
                    <div className='w-full flex_center'>
                        <div
                            className='w-full h-[60px] bg-white border rounded-xl mb-8 shadow px-4 flex_center'>
                            <p>조회 데이터가 없습니다.</p>
                        </div>
                    </div>
            }
        </div>
    )
}