import {useSelector} from "react-redux";

export default function Loading() {
    const {isLoading} = useSelector((state : any) => state.loading);
    return (
        <div className={''}>
            {
                isLoading === false  ?
                    '' :
                    <div className={'fixed inset-0 bg-black bg-opacity-10 z-[100]'}>
                        <div className={'flip-to-square'}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
            }
        </div>
    );
}